import React, { useState, useEffect } from "react";
import styles from '../css/Menu.module.css';
import { Link, useParams } from 'react-router-dom';

export default function Menu() {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const { valor } = useParams();
  
  const [isDepartmentVisible, setIsDepartmentVisible] = useState(true);
  const [isCityVisible, setIsCityVisible] = useState(false);
  const [isGenreVisible, setIsGenreVisible] = useState(false);

  // Estado para almacenar los departamentos
  const [departamentos, setDepartamentos] = useState([]);
    // Estado para almacenar los ciudades
  const [ciudades, setCiudades] = useState([]);
     // Estado para almacenar los ghneros
     const [generos, setGeneros] = useState([]);

  useEffect(() => {
    if (valor) {
      const filtersFromUrl = valor.split(",");
      setSelectedFilters(filtersFromUrl);
    } else {
      setSelectedFilters([]);
    }
  }, [valor]);

  const handleFilterClick = (filter) => {
    setSelectedFilters((prevFilters) => {
      if (prevFilters.includes(filter)) {
        return prevFilters.filter((f) => f !== filter);
      } else {
        return [...prevFilters, filter];
      }
    });
  };

  const generateLink = (path) => {
    const newFilters = selectedFilters.includes(path)
      ? selectedFilters.filter((filter) => filter !== path)
      : [...selectedFilters, path];
    return newFilters.length ? `/emisoras/${newFilters.join(",")}` : "/emisoras/";
  };

  const toggleDepartmentVisibility = () => setIsDepartmentVisible(!isDepartmentVisible);
  const toggleCityVisibility = () => setIsCityVisible(!isCityVisible);
  const toggleGenreVisibility = () => setIsGenreVisible(!isGenreVisible);

  // useEffect para obtener los departamentos desde la API
  useEffect(() => {
    const fetchDepartamentos = async () => {
      try {
        const response = await fetch('https://dialcolombia.com:3002/departamentos');
        const data = await response.json();
        setDepartamentos(data);
      } catch (error) {
        console.error("Error al cargar departamentos:", error);
      }
    };
    fetchDepartamentos();
  }, []);
  
  // useEffect para obtener los ciudades desde la API
  useEffect(() => {
    const fetchCiudades = async () => {
      try {
        const response = await fetch('https://dialcolombia.com:3002/ciudad');
        const data = await response.json();
        setCiudades(data);
      } catch (error) {
        console.error("Error al cargar Ciudades:", error);
      }
    };
    fetchCiudades();
  }, []);

  // useEffect para obtener los generos desde la API
  useEffect(() => {
    const fetchGeneros = async () => {
      try {
        const response = await fetch('https://dialcolombia.com:3002/generos');
        const data = await response.json();
        setGeneros(data);
      } catch (error) {
        console.error("Error al cargar Generos:", error);
      }
    };
    fetchGeneros();
  }, []);

  return (
    <div className={styles.menu}>
      <div className={styles.divmenu}>
        <h3>FILTRAR EMISORAS</h3>
        <div className={styles.categorias}>

          {/* Filtro Departamento */}
          <div>
            <button
              className={styles.opcion_filtro}
              onClick={toggleDepartmentVisibility}
            >
              Departamento <img src={`../img/icon/${isDepartmentVisible ? "flecha_abajo.svg" : "flecha_arriba.svg"}`} />
            </button>
            {isDepartmentVisible && (
              <div className={styles.contenedor_etiquetas}>
                {departamentos.map((departamento) => (
                  <Link
                    to={generateLink(departamento.Nombre_depto)}
                    key={departamento.Nombre_depto}
                    className={styles.link_etiqueta}
                  >
                    <button
                      className={`${styles.etiqueta} ${selectedFilters.includes(departamento.Nombre_depto) ? styles.etiqueta_activa : ""}`}
                      onClick={() => handleFilterClick(departamento.Nombre_depto)}
                    >
                      {departamento.Nombre_depto.charAt(0).toUpperCase() + departamento.Nombre_depto.slice(1)}{" "}
                      <img
                        src={`../img/icon/${selectedFilters.includes(departamento.Nombre_depto) ? "check.svg" : "mas.svg"}`}
                        alt="icono"
                      />
                    </button>
                  </Link>
                ))}
              </div>
            )}
          </div>

          {/* Filtro Ciudades */}
          <div>
            <button
              className={styles.opcion_filtro}
              onClick={toggleCityVisibility}
            >
              Ciudades <img src={`../img/icon/${isCityVisible ? "flecha_abajo.svg" : "flecha_arriba.svg"}`} />
            </button>
            {isCityVisible && (
              <div className={styles.contenedor_etiquetas}>
                {ciudades.map((ciudad) => (
                  <Link
                    to={generateLink(ciudad.Nombre_ciudad)}
                    key={ciudad.Nombre_ciudad}
                    className={styles.link_etiqueta}
                  >
                    <button
                      className={`${styles.etiqueta} ${selectedFilters.includes(ciudad.Nombre_ciudad) ? styles.etiqueta_activa : ""}`}
                      onClick={() => handleFilterClick(ciudad.Nombre_ciudad)}
                    >
                      {ciudad.Nombre_ciudad.charAt(0).toUpperCase() + ciudad.Nombre_ciudad.slice(1)}{" "}
                      <img
                        src={`../img/icon/${selectedFilters.includes(ciudad.Nombre_ciudad) ? "check.svg" : "mas.svg"}`}
                        alt="icono"
                      />
                    </button>
                  </Link>
                ))}
              </div>
            )}
          </div>

          {/* Filtro Género */}
          <div>
            <button
              className={styles.opcion_filtro}
              onClick={toggleGenreVisibility}
            >
              Género <img src={`../img/icon/${isGenreVisible ? "flecha_abajo.svg" : "flecha_arriba.svg"}`} />
            </button>
            {isGenreVisible && (
              <div className={styles.contenedor_etiquetas}>
                {generos.map((genero) => (
                  <Link
                    to={generateLink(genero.genero)}
                    key={genero.genero}
                    className={styles.link_etiqueta}
                  >
                    <button
                      className={`${styles.etiqueta} ${selectedFilters.includes(genero.genero) ? styles.etiqueta_activa : ""}`}
                      onClick={() => handleFilterClick(genero.genero)}
                    >
                      {genero.genero.charAt(0).toUpperCase() + genero.genero.slice(1)}{" "}
                      <img
                        src={`../img/icon/${selectedFilters.includes(genero.genero) ? "check.svg" : "mas.svg"}`}
                        alt="icono"
                      />
                    </button>
                  </Link>
                ))}
              </div>
            )}
          </div>

        </div>
      </div>
    </div>
  );
}
