import React from "react";
import styles from '../css/formulariocontacto.module.css'
import { useState, useEffect } from "react";




export default function PublicidadRegistro() {

    const [titulo, setTitulo] = useState('Contactanos');
    useEffect(() => {
      document.title = titulo;
    }, [titulo]);

  return (
    <div className={styles.formulario_contacto}>
      <div>
        <div>
          <h1>Ponte en contacto con nuestro equipo</h1>
          <p>Déjanos un mensaje y te contactaremos para despejar tus dudas</p>
        </div>
        <div>
          <div>
            <img src="../img/icon/mensaje.svg" alt="" />
            <h4>¿Estas interesado?</h4>
            <p>nuestro equipo resolverá todas tus dudas</p>
            <span>ventas@dialcolombia.com</span>
          </div>
          <div>
            <img src="../img/icon/comunicacion.svg" alt="" />
            <h4>Chat de soporte</h4>
            <p>estamos aquí para ayudarte en todo momento</p>
            <span>soporte@dialcolombia.com</span>
          </div>
          <div>
            <img src="../img/icon/puntomaps.svg" alt="" />
            <h4>Visitanos</h4>
            <p>Visita nuestra oficina en Colombia</p>
            <span><a  target="_blank" href="https://maps.app.goo.gl/1uDGLSdCJETjTyp9A">ver en google maps </a></span>
          </div>
          <div>
            <img src="../img/icon/llamada.svg" alt="" />
            <h4>Llamanos</h4>
            <p>Lunes - Viernes en horario oficina</p>
            <span><a href="tel:3143541140">ver contacto</a></span>
          </div>
        </div>
        <div>
          <form >
            <div>
              <label >Nombre completo</label>
              <input
                placeholder="ingresa tu nombre completo"
                type="text"
                id="nombre"
              />
            </div>
            <div>
              <label >Número de teléfono</label>
              <input
                placeholder="ingresa tu numero telefonico"
                type="tel"
                id="telefono"

              />
            </div>
            <div>
              <label >Correo electrónico</label>
              <input
                placeholder="ingresa tu correo electronico"
                type="email"
                id="correo"
              />
            </div>
            <div>   
              <label>Mensaje</label>
              <textarea
                placeholder="quiero que me contacten para..."
                id="mensaje"
              />
            </div>
            <div>

            </div>
            <div>
              <button type="submit">Enviar</button>
            </div>
            
          </form>
        </div> 
      </div>
    </div>
  );
}