import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Emisora from "./routes/Emisora";


import Header from "./components/General/javascript/Header";
import Footer from "./components/General/javascript/Footer";

import Inicio from "./routes/Inicio";
import Contacto from "./routes/Contacto";

const AppLayout = ({ children }) => (
  <div className="todo">
    <Header />
    {children}
    <Footer/>

  </div>
);

const App = () => {
  /*useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault(); // Evitar que aparezca el menú contextual al hacer clic derecho
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);*/

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout><Inicio /></AppLayout>,
    errorElement: <h1>404</h1>,
  },
  {
    path: '/emisoras/:valor',
    element: <AppLayout><Inicio /></AppLayout>,
    errorElement: <h1>404</h1>,
  },
  {
    path: '/emisoras',
    element: <AppLayout><Inicio /></AppLayout>,
    errorElement: <h1>404</h1>,
  },
  {
    path: '/contacto',
    element: <AppLayout><Contacto/></AppLayout>,
    errorElement: <h1>404</h1>,
  },
  {
    path: '/emisora/:emisoraid',
    element: <AppLayout><Emisora /></AppLayout>,
    errorElement: <h1>404</h1>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
