import React from 'react';
import { Link } from "react-router-dom";
import styles from "../css/Header.module.css" 

export default function Header() {
    return(
        <header  className={styles.header}>
            <section className={styles.section}>
                <div>
                    <img className="logo" src="../img/logos/dialcolombia.png" alt="" />
                </div>
                <nav className={styles.nav}>
                    <ul>
                        <li>
                            <Link to={`/`}><p>Emisoras</p></Link>
                        </li>
                        <li>
                            <Link to={`/`}><p>Nosotros</p></Link>
                        </li>
                        <li>
                            <Link to={`/`}><p>Articulos</p></Link>
                        </li>
                        <li>
                            <Link to={`/contacto`}><p>Contacto</p></Link>
                        </li>
                    </ul>
                </nav>
            </section>
        </header>
    );
}