import React, { useState, useEffect } from "react";
import ErrorPage from "../../../routes/ErrorPage";
import { useParams, useNavigate, Link } from "react-router-dom";
import styles from "../css/EmisoraInterna.module.css";
import EmisoraPublicidad from "../../../utils/EmisoraPublicidad";
import DerteccionesAcr from "./DerteccionesAcr";

import { Url } from "../../../constants/global";


export default function EmisoraInterna() {
  const { emisoraid } = useParams();
  const [emisoraData, setEmisoraData] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // Estado para manejar el input
  const navigate = useNavigate(); // Hook para redirigir

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${Url}/emisoras/` + emisoraid);
      const data = await response.json();
      setEmisoraData(data);
      document.title = `${data.Nombre_emisora} ${data.dial} ${data.band} - ${data.nombre_ciudad} `;
    };
    fetchData();
  }, [emisoraid]);

  const handleSearch = () => {
    if (searchTerm.trim() !== "") {
      navigate(`/emisoras/${searchTerm}`);
    } 
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };
    
    return (
        <div className={styles.emisora}>
          {emisoraData ? (
            <div className={styles.emisoradiv}>
              <div className={styles.searchContainer}>
              <input
                  className={styles.buscador}
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={handleKeyDown} // Detecta la tecla Enter
                  placeholder="Ingresa el término de búsqueda"
              />
      <button onClick={handleSearch}>Buscar</button>

              </div>

                  <div className={styles.emisoraruta}>
                      <span><p><Link to={`/emisoras`}> Emisoras </Link> &gt;&nbsp;</p></span>
                      <span><p> <Link to={`/emisoras/${emisoraData.Nombre_depto}`}> {emisoraData.Nombre_depto} </Link>&gt;&nbsp;</p></span>
                      <span><p><Link to={`/emisoras/${emisoraData.nombre_ciudad}`}> {emisoraData.nombre_ciudad}</Link></p></span>
                  </div>

                  <div className={styles.emisorapublicidad}>
                      <div className={styles.emisoramarca}>
                        <div>
                          <div className={styles.contentlogo}>
                            <img id="inicio_pagina_interna" className={styles.logo} src={`../img/logos/${emisoraData.stream_id}.jpg`} />
                          </div>
                          <div className={styles.ficha} >
                            <div className={styles.fichanombre}>
                              <h1>{emisoraData.Nombre_emisora} - {emisoraData.nombre_ciudad} </h1>
                             
                              <p className={styles.dial} >{emisoraData.dial} {emisoraData.band}</p>
                            </div>
                            <div className={styles.contbotonplay}>
                              <button className={styles.botonplay}
                              onClick={(e) => {
                                  e.preventDefault(); // Prevenir la navegación predeterminada
                                  const externalPageURL = `https://ref.m.letio.com/?ref=${emisoraData.stream_id}`;
                                  /*&elp=2024-03-15t10:23:05*/
                                  const popupOptions = 'width=500,height=2000';
                                  window.open(externalPageURL, '_blank', popupOptions);
                                }}>
                                  <span className={styles.play} >
                                      <img src="../img/icon/escuchar.svg" alt="" />
                                      <span>ESCUCHAR EN VIVO</span>
  
                                  </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      <div>
                        <div>
                          <p>Emisora colombiana del tipo {emisoraData.tipo} ubicada del departamento de {emisoraData.Nombre_depto} - {emisoraData.nombre_ciudad} con programación principalmente
                            compuesta de {emisoraData.tag_genero_uno} y {emisoraData.tag_genero_dos}, una emisora especializada en contenido {emisoraData.tag_contenido} afiliada a dialcolombia 
                            identificada con dial numero {emisoraData.dial} y banda {emisoraData.band} con sistema {emisoraData.Sistema} y de la region colombiana ({emisoraData.region})
                             </p>
                        </div>
                        <div>
                          <span className={styles.tags}>
                            <Link to={`/emisoras/contenido:${emisoraData.tag_contenido}`}> <p style={{textTransform: 'capitalize'}}>#{emisoraData.tag_contenido}&nbsp;</p></Link>
                            <Link to={`/emisoras/${emisoraData.tag_genero_uno}`}> <p style={{ textTransform: 'capitalize' }}>#{emisoraData.tag_genero_uno}&nbsp;</p></Link>
                            <Link to={`/emisoras/${emisoraData.tag_genero_dos}`}><p style={{ textTransform: 'capitalize' }}> #{emisoraData.tag_genero_dos}&nbsp;</p></Link> 
                            <Link to={`/emisoras/${emisoraData.tipo}`}><p style={{ textTransform: 'capitalize' }}>#{emisoraData.tipo}</p></Link>
                          </span>
                        </div>
                      </div>
                      </div>
                      
                </div>
                <div className={styles.ficha_tecnica}>
                  <div>
                    <div>
                      <h3>Datos de la emisora</h3>
                    </div>
                    <div>
                      <div>Departamento: {emisoraData.Nombre_depto}</div>
                      <div>Ciudad: {emisoraData.nombre_ciudad}</div>
                    </div>
                    <div>
                      <div>MINTIC: {emisoraData.mintic}</div>
                      <div>Distintivo: {emisoraData.distintivo}</div>          
                    </div>
                    <div>
                      <div>Dial: {emisoraData.dial}</div>
                      <div>Banda: {emisoraData.band}</div>
                    </div>
      
                  </div>
                </div>
                  <div>
                    <DerteccionesAcr emisoraid={emisoraid} />
                  </div>
                  
            </div>
          ) : (
            <ErrorPage />
          )}
        </div>
      );
}