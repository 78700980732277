import styles from "../routes/css/Emisora.module.css"
import FormularioContacto from "../components/Contacto/javascript/FormularioContacto"
import Slider from "../components/Inicio/javascript/Slider";




export default function Contacto() {
    return (
    <div >
        <Slider/>
        <FormularioContacto/>
    </div>
);
    } 