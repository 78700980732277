import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from "../../Inicio/css/emisorasGrid.module.css";
import { Url } from '../../../constants/global';

const Recomendados = () => {
  const [emisoras, setEmisoras] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setLoading] = useState(true);

  const emisorasPerPage = 12;
  const location = useLocation(); // Hook para obtener los parámetros de la URL

  // Obtener el valor de tag_genero_uno de la URL (si existe)
  const queryParams = new URLSearchParams(location.search);
  const tagGeneroUnoFilter = queryParams.get("tag_genero_uno"); // Asumiendo que el filtro se pasa como "tagGeneroUno"

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${Url}/emisoras`);
      const data = await response.json();

      // Si se ha especificado un filtro por tag_genero_uno, lo aplicamos
      const filteredEmisoras = tagGeneroUnoFilter
        ? data.filter(emisora => emisora.tag_genero_uno.toLowerCase() === tagGeneroUnoFilter.toLowerCase())
        : data;

      setEmisoras(filteredEmisoras);
    } catch (error) {
      console.error("Error al cargar emisoras:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [tagGeneroUnoFilter]); // Vuelve a ejecutar cuando cambie el filtro

  const resetComponent = () => {
    setPageNumber(0);
    setEmisoras([]);
    setLoading(true);
    fetchData();
  };

  const pagesVisited = pageNumber * emisorasPerPage;
  const pageCount = Math.ceil(emisoras.length / emisorasPerPage);

  const displayEmisoras = emisoras
    .slice(pagesVisited, pagesVisited + emisorasPerPage)
    .map((emisora) => (
        
      <Link
        className={styles.linkSinSubrayado}
        key={emisora.id}
        to={`/emisora/${emisora.stream_id}`}
        onClick={() => {
          window.scrollTo({ top: 400, left: 0, behavior: "smooth" }); // Desplaza hacia el inicio con animación
        }}
      >
        
        <div className={styles.emisoradiv}>
          <div className={styles.emisoraimg_container}>
            <img
              onClick={(e) => {
                e.preventDefault();
                resetComponent(); // Resetea el componente al hacer clic
                const externalPageURL = `https://ref.m.letio.com/?ref=${emisora.stream_id}`;
                const popupOptions = 'width=500,height=2000';
                window.open(externalPageURL, '_blank', popupOptions);
              }}
              className={styles.emisoraimg}
              src={`../img/logos/${emisora.stream_id}.jpg`}
              alt={emisora.Nombre_emisora}
            />
            <div
              className={styles.iconOverlay}
              dangerouslySetInnerHTML={{
                __html: `
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="100" height="100" viewBox="0 0 353.000000 335.000000"
                    preserveAspectRatio="xMidYMid meet">
                    <g transform="translate(0.000000,335.000000) scale(0.100000,-0.100000)"
                    fill="#FFFFFF" stroke="none">
                    <path d="M1660 2824 c-84 -11 -201 -39 -280 -66 -191 -68 -331 -157 -481 -307
                    -188 -188 -299 -392 -355 -651 -26 -119 -26 -381 0 -500 56 -259 167 -463 355
                    -651 188 -188 392 -299 651 -355 119 -26 381 -26 500 0 259 56 463 167 651
                    355 188 188 299 392 355 651 26 119 26 381 0 500 -56 259 -167 463 -355 651
                    -185 185 -379 293 -631 350 -83 19 -331 33 -410 23z m340 -119 c486 -85 870
                    -469 955 -955 8 -47 15 -137 15 -200 0 -306 -110 -583 -320 -804 -361 -380
                    -919 -476 -1384 -238 -511 261 -759 863 -580 1407 134 405 488 713 906 789
                    101 19 305 19 408 1z"/>
                    <path d="M1496 2064 c-14 -14 -16 -74 -16 -514 0 -540 -1 -530 53 -530 12 0
                    195 112 407 248 384 247 385 248 385 282 0 34 -1 35 -385 282 -212 136 -395
                    248 -407 248 -12 0 -29 -7 -37 -16z m467 -373 c114 -74 207 -137 207 -141 0
                    -4 -93 -67 -207 -141 -115 -73 -245 -157 -290 -186 l-83 -53 0 380 0 380 83
                    -53 c45 -29 175 -113 290 -186z"/>
                    </g>
                  </svg>
                `,
              }}
            />
          </div>
          <h2
            onClick={(e) => {
              e.preventDefault();
              resetComponent(); // Resetea el componente al hacer clic
              const externalPageURL = `https://ref.m.letio.com/?ref=${emisora.stream_id}`;
              const popupOptions = 'width=500,height=2000';
              window.open(externalPageURL, '_blank', popupOptions);
            }}
          >
            {emisora.Nombre_emisora}
          </h2>
          <p
            onClick={(e) => {
              e.preventDefault();
              resetComponent(); // Resetea el componente al hacer clic
              const externalPageURL = `https://ref.m.letio.com/?ref=${emisora.stream_id}`;
              const popupOptions = 'width=500,height=2000';
              window.open(externalPageURL, '_blank', popupOptions);
            }}
          >
            {emisora.nombre_ciudad}
          </p>
        </div>
      </Link>
    ));

  return (
    <div className={styles.buscadordiv}>
      {loading ? (
        <div className={styles.loading_animation}>
         <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>
        <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>
        <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>
        <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>
        <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>
        <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>
        <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>
        <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>
        <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>
        <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div><div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>
        <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>


        </div>
      ) : (
       <div>
        <h3 className={styles.titulo_recomendados}>Emisoras Sugeridas</h3>
        <div className={styles.emisoraGrid}>
            {displayEmisoras}
        </div>
        </div>
      )}
    </div>
  );
};

export default Recomendados;
